<template>
    <div class="vehicleboardassociation">
        <el-card class="box-card father-card" shadow="never">
            <!-- 头部区域 -->
            <el-row class="btns">
                <el-form ref="form" :model="form" inline>
                    <!-- 搜索框 -->
                    <el-form-item label="车牌号码">
                        <el-input
                            v-model="form.carNum"
                            placeholder="请输入车牌号码"
                            clearable
                        ></el-input>
                    </el-form-item>
                     <el-form-item label="卡号查询">
                        <el-input
                            v-model="form.simPhone"
                            placeholder="请输入卡号"
                            clearable
                        ></el-input>
                    </el-form-item>
                     <el-form-item label="平板id查询">
                        <el-input
                            v-model="form.padName"
                            placeholder="请输入平板id"
                            clearable
                        ></el-input>
                    </el-form-item>
                   <el-form-item label="机构名称">
						<el-select v-model="form.companyId" placeholder="请选择机构" clearable>
							<el-option v-for="item in institutionsOptions" :key="item.uuid" :label="item.companyName"
								:value="item.uuid">
							</el-option>
						</el-select>
				    </el-form-item>
                    <!-- 按钮区域 -->
                    <el-form-item>
                        <el-button
                            type="primary"
                            icon="el-icon-search"
                            @click="search()"
                            >搜索</el-button
                        >
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            @click="addBtn()"
                            >添加
                        </el-button>
                        <el-button
                            type="primary"
                            icon="el-icon-plus"
                            @click="reset()"
                            >重置
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <!-- 表格区域 -->
            <el-row class="tab">
                <el-table
                    ref="multipleTable"
                    :data="tabData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    highlight-current-row
                    stripe
                    border
                    :header-cell-style="rowClass"
                >
                    <el-table-column
                        prop="carNum"
                        label="车牌号码"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="companyName"
                        label="机构名称"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="gpsName"
                        label="GPS设备名称"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="gpsSn"
                        label="GPS设备编码"
                        align="center"
                    ></el-table-column>
                      <el-table-column
                        prop="gpsCalcTypeName"
                        label="里程计算方式"
                        align="center"
                    ></el-table-column>

                    <!-- <el-table-column
                        prop="terminalSn"
                        label="车载终端编码"
                        align="center"
                    ></el-table-column> -->
                    <el-table-column
                        prop="padName"
                        label="车载平板名称"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="simPhone"
                        label="车载SIM卡电话号码"
                        align="center"
                    ></el-table-column>
                    <el-table-column prop="status" label="状态" align="center">
                        <template slot-scope="scope">
                            <span
                                effect="dark"
                                v-if="scope.row.status === '0'"
                                >{{ '新增' }}</span
                            >
                            <span
                                effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '1'"
                                >{{ '提交注册' }}
                            </span>
                            <span
                                effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '2'"
                                >{{ '已注册' }}</span
                            >
                            <span
                                effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '3'"
                                >{{ '提交注销' }}
                            </span>
                            <span type="info" effect="dark" v-else>{{
                                '已注销'
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        align="center"
                        min-width="200px"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="success"
                                @click="edit(scope.row,true)"
                                >查看详情</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                icon="el-icon-edit"
                                @click="edit(scope.row,false)"
                                >修改
                            </el-button>
                            <el-button
                                size="mini"
                                type="danger"
                                icon="el-icon-delete-solid"
                                @click="open(scope.row)"
                                >删除
                            </el-button>
                            <el-button
                                size="mini"
                                type="success"
                                @click="register(scope.row)"
                                >注册</el-button
                            >
                            <!-- <el-button size="mini" type="success" @click="subscribe(scope.row)">订阅</el-button> -->
                            <el-button
                                size="mini"
                                type="primary"
                                icon="el-icon-edit"
                                @click="logout(scope.row)"
                                >注销
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handlePageNumChange"
                    :current-page="paging.PageNo"
                    :page-sizes="[10, 15, 20, 50]"
                    :page-size="paging.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </el-row>
            <!-- 机构详情弹出框 -->
            <el-dialog
                :visible.sync="orderDialogFormVisible"
                class="order-dialog"
            >
                <span slot="title" class="dialog-Title"
                    >车载设备与车辆关联详情</span
                >
                <el-form
                    :model="selectForm"
                    inline
                    ref="selectForm"
                    :rules="selectFormRules"
                    class="selectForm"
                >
                    <!-- 车牌号码 -->
                    <el-popover
                        disabled
                        placement="bottom-start"
                        v-model="isReviseVerification.carNum.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.carNum.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.carNum.input
                                                .carNum
                                        "
                                        placeholder="请输入车牌号码"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchReviseCarNum(
                                            isReviseVerification.carNum
                                        )
                                    "
                                    >搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrReviseCarNum()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.carNum.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="carNum"
                                        label="车牌号码"
                                    ></el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selReviseCarNum(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchReviseCarNum(isReviseVerification.carNum)
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.carNum.isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.carNum.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.carNum.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.carNum.text
                                        ? isReviseVerification.carNum.text
                                        : '请选择车牌号码'
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- sim -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.simPhone.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.simPhone.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.simPhone.input
                                                .simPhone
                                        "
                                        placeholder="请输入车载SIM卡"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchReviseSimPhone(
                                            isReviseVerification.simPhone
                                        )
                                    "
                                    >搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrReviseSimPhone()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.simPhone.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceSn"
                                        :label="
                                            isReviseVerification.simPhone.title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selReviseSimPhone(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchReviseSimPhone(
                                    isReviseVerification.simPhone
                                )
                            "
                        >
                            <div  class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.simPhone
                                            .isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.simPhone.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.simPhone.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.simPhone.text
                                        ? isReviseVerification.simPhone.text
                                        : '请选择' +
                                          isReviseVerification.simPhone.title
                                }}
                            </div>
                        </div>
                    </el-popover>

                    <!-- 车载终端 -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.terminalName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.terminalName
                                                .title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.terminalName
                                                .input.terminalName
                                        "
                                        placeholder="请输入车载终端名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchReviseTerminalName(
                                            isReviseVerification.terminalName
                                        )
                                    "
                                    >搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrReviseTerminalName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.terminalName
                                            .tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceSn"
                                        :label="
                                            isReviseVerification.terminalName
                                                .title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selReviseTerminalName(
                                                        scope.row
                                                    )
                                                "
                                                >确认</el-button
                                            >
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchReviseTerminalName(
                                    isReviseVerification.terminalName
                                )
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.terminalName
                                            .isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.terminalName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.terminalName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.terminalName.text
                                        ? isReviseVerification.terminalName.text
                                        : '请选择' +
                                          isReviseVerification.terminalName
                                              .title
                                }}
                            </div>
                        </div>
                    </el-popover>

                    <!-- 车载硬盘 -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.diskName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.diskName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.diskName.input
                                                .diskName
                                        "
                                        placeholder="请输入车载硬盘名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchReviseDiskName(
                                            isReviseVerification.diskName
                                        )
                                    "
                                    >搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrReviseDiskName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.diskName.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceSn"
                                        :label="
                                            isReviseVerification.diskName.title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selReviseDiskName(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchReviseDiskName(
                                    isReviseVerification.diskName
                                )
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.diskName
                                            .isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.diskName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.diskName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.diskName.text
                                        ? isReviseVerification.diskName.text
                                        : '请选择' +
                                          isReviseVerification.diskName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 车载平板 -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.padName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.padName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.padName.input
                                                .padName
                                        "
                                        placeholder="请输入车载硬盘名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchRevisePadName(
                                            isReviseVerification.padName
                                        )
                                    "
                                    >搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrRevisePadName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.padName.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceName"
                                        :label="
                                            isReviseVerification.padName.title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="deviceSn"
                                        label="手机号"
                                    ></el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selRevisePadName(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchRevisePadName(
                                    isReviseVerification.padName
                                )
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.padName.isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.padName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.padName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.padName.text
                                        ? isReviseVerification.padName.text
                                        : '请选择' +
                                          isReviseVerification.padName.title
                                }}
                            </div>
                        </div>
                    </el-popover>

                    <!-- 设备编码 -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.terminalSnName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.terminalSnName
                                                .title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.terminalSnName
                                                .input.terminalSnName
                                        "
                                        placeholder="请输入设备编码名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchSnName(
                                            isReviseVerification.terminalSnName
                                        )
                                    "
                                    >搜索
                                </el-button>
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrSnName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.terminalSnName
                                            .tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceName"
                                        :label="
                                            isReviseVerification.terminalSnName
                                                .title
                                        "
                                    >
                                    </el-table-column>
                                    <!-- <el-table-column prop="deviceSn" label="手机号"></el-table-column> -->
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selSnName(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchSnName(
                                    isReviseVerification.terminalSnName
                                )
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.terminalSnName
                                            .isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.terminalSnName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.terminalSnName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.terminalSnName.text
                                        ? isReviseVerification.terminalSnName
                                              .text
                                        : '请选择' +
                                          isReviseVerification.terminalSnName
                                              .title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 里程计算方法 -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.gpsCalcName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.gpsCalcName
                                            .tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="key"
                                        :label="
                                            isReviseVerification.gpsCalcName
                                                .title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selgpsCalcDetailName(
                                                        scope.row
                                                    )
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchgpsCalcDetailNum(
                                    isReviseVerification.gpsCalcName
                                )
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.gpsCalcName
                                            .isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.gpsCalcName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.gpsCalcName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.gpsCalcName.text
                                        ? isReviseVerification.gpsCalcName.text
                                        : '请选择' +
                                          isReviseVerification.gpsCalcName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 车载GPS -->
                    <el-popover
                    :disabled="isDisabled"
                        placement="bottom-start"
                        v-model="isReviseVerification.gpsName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isReviseVerification.gpsName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isReviseVerification.gpsName.input
                                                .gpsName
                                        "
                                        placeholder="请输入车载GPS名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchReviseGpsName(
                                            isReviseVerification.gpsName
                                        )
                                    "
                                    >搜索
                                </el-button>
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrReviseGpsName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isReviseVerification.gpsName.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceName"
                                        :label="
                                            isReviseVerification.gpsName.title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="deviceSn"
                                        label="设备编码"
                                    ></el-table-column>
                                     <el-table-column
                                        prop="deviceFactory"
                                        label="设备厂家"
                                    ></el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selReviseGpsName(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchReviseGpsName(
                                    {
                                        input:{
                                            gpsName:isReviseVerification.carNum.text
                                        }
                                    }
                                    // isReviseVerification.gpsName
                                )
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isReviseVerification.gpsName.isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isReviseVerification.gpsName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isReviseVerification.gpsName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isReviseVerification.gpsName.text
                                        ? isReviseVerification.gpsName.text
                                        : '请选择' +
                                          isReviseVerification.gpsName.title
                                }}
                            </div>
                        </div>
                    </el-popover>

                     <el-form-item label="是否强制检测海康信号源" prop="isForceCheckHk">
                            <el-select v-model="selectForm.isForceCheckHk" placeholder="请选择">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input
                        :disabled="isDisabled"
                            v-model="selectForm.remark"
                            autocomplete="off"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="sure()">确 认</el-button>
                </div>
            </el-dialog>
            <!-- 添加弹出框 -->
            <el-dialog
                :visible.sync="addDialogFormVisible"
                class="add-dialog"
                @close="addDialogClose()"
            >
                <span slot="title" class="dialog-Title"
                    >添加车载设备与车辆关联</span
                >
                <el-form
                    :model="addForm"
                    inline
                    ref="addForm"
                    class="addForm"
                    :rules="addFormRules"
                >
                    <!-- 车牌号码 -->

                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.carNum.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.carNum.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.carNum.input.carNum
                                        "
                                        placeholder="请输入车牌号码"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="searchCarNum(isVerification.carNum)"
                                    >搜索
                                </el-button>
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrCarNum()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="isVerification.carNum.tableData"
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="carNum"
                                        label="车牌号码"
                                    ></el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selCarNum(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="searchCarNum(isVerification.carNum)"
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="isVerification.carNum.isMandatory"
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.carNum.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.carNum.text ? '' : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.carNum.text
                                        ? isVerification.carNum.text
                                        : '请选择车牌号码'
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- sim -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.simPhone.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.simPhone.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.simPhone.input
                                                .simPhone
                                        "
                                        placeholder="请输入车载SIM卡"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchSimPhone(isVerification.simPhone)
                                    "
                                >
                                    搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrSimPhone()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="isVerification.simPhone.tableData"
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceSn"
                                        :label="isVerification.simPhone.title"
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selSimPhone(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="searchSimPhone(isVerification.simPhone)"
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="isVerification.simPhone.isMandatory"
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.simPhone.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.simPhone.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.simPhone.text
                                        ? isVerification.simPhone.text
                                        : '请选择' +
                                          isVerification.simPhone.title
                                }}
                            </div>
                        </div>
                    </el-popover>

                    <!-- 车载终端 -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.terminalName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.terminalName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.terminalName.input
                                                .terminalName
                                        "
                                        placeholder="请输入车载终端名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchTerminalName(
                                            isVerification.terminalName
                                        )
                                    "
                                    >搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrTerminalName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isVerification.terminalName.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceSn"
                                        :label="
                                            isVerification.terminalName.title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selTerminalName(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchTerminalName(isVerification.terminalName)
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isVerification.terminalName.isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.terminalName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.terminalName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.terminalName.text
                                        ? isVerification.terminalName.text
                                        : '请选择' +
                                          isVerification.terminalName.title
                                }}
                            </div>
                        </div>
                    </el-popover>

                    <!-- 车载硬盘 -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.diskName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.diskName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.diskName.input
                                                .diskName
                                        "
                                        placeholder="请输入车载硬盘名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchDiskName(isVerification.diskName)
                                    "
                                >
                                    搜索</el-button
                                >
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrDiskName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="isVerification.diskName.tableData"
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceSn"
                                        :label="isVerification.diskName.title"
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selDiskName(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="searchDiskName(isVerification.diskName)"
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="isVerification.diskName.isMandatory"
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.diskName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.diskName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.diskName.text
                                        ? isVerification.diskName.text
                                        : '请选择' +
                                          isVerification.diskName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 车载平板 -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.padName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.padName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.padName.input.padName
                                        "
                                        placeholder="请输入车载硬盘名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchPadName(isVerification.padName)
                                    "
                                    >搜索
                                </el-button>
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrPadName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="isVerification.padName.tableData"
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceName"
                                        :label="isVerification.padName.title"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="deviceSn"
                                        label="手机号"
                                    ></el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selPadName(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="searchPadName(isVerification.padName)"
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="isVerification.padName.isMandatory"
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.padName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.padName.text ? '' : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.padName.text
                                        ? isVerification.padName.text
                                        : '请选择' +
                                          isVerification.padName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 设备编码 -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.terminalSnName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.terminalSnName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.terminalSnName.input
                                                .terminalSnName
                                        "
                                        placeholder="请输入设备编码名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchSnName(
                                            isVerification.terminalSnName
                                        )
                                    "
                                    >搜索
                                </el-button>
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrSnName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="
                                        isVerification.terminalSnName.tableData
                                    "
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceName"
                                        :label="
                                            isVerification.terminalSnName.title
                                        "
                                    >
                                    </el-table-column>
                                    <!-- <el-table-column prop="deviceSn" label="手机号"></el-table-column> -->
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selSnName(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="searchSnName(isVerification.terminalSnName)"
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isVerification.terminalSnName
                                            .isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.terminalSnName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.terminalSnName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.terminalSnName.text
                                        ? isVerification.terminalSnName.text
                                        : '请选择' +
                                          isVerification.terminalSnName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 车载GPS -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.gpsName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-head">
                                <div class="head-input">
                                    <span
                                        >{{
                                            isVerification.gpsName.title
                                        }}:&nbsp;&nbsp;</span
                                    >
                                    <el-input
                                        v-model="
                                            isVerification.gpsName.input.gpsName
                                        "
                                        placeholder="请输入车载GPS名称"
                                        size="mini"
                                    ></el-input>
                                </div>
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="
                                        searchGpsNum(isVerification.gpsName)
                                    "
                                    >搜索
                                </el-button>
                                <el-button
                                    type="info"
                                    size="mini"
                                    @click="clrGpsName()"
                                    >重置</el-button
                                >
                            </div>
                            <div class="content-table">
                                <el-table
                                    :data="isVerification.gpsName.tableData"
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="deviceName"
                                        :label="isVerification.gpsName.title"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        prop="deviceSn"
                                        label="设备编码"
                                    ></el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="selGpsName(scope.row)"
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="searchGpsNum(isVerification.gpsName)"
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="isVerification.gpsName.isMandatory"
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.gpsName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.gpsName.text ? '' : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.gpsName.text
                                        ? isVerification.gpsName.text
                                        : '请选择' +
                                          isVerification.gpsName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- gps计算方法 -->
                    <el-popover
                        placement="bottom-start"
                        v-model="isVerification.gpsCalcName.is"
                        trigger="click"
                        class="dy-popover"
                    >
                        <div class="dy-content">
                            <div class="content-table">
                                <el-table
                                    :data="isVerification.gpsCalcName.tableData"
                                    height="250"
                                    border
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="key"
                                        :label="
                                            isVerification.gpsCalcName.title
                                        "
                                    >
                                    </el-table-column>
                                    <el-table-column label="操作" width="60">
                                        <template slot-scope="scope">
                                            <el-button
                                                type="text"
                                                size="small"
                                                @click="
                                                    selgpsCalcName(scope.row)
                                                "
                                                >确认
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div
                            class="dy-sel"
                            slot="reference"
                            @click="
                                searchgpsCalcNum(isVerification.gpsCalcName)
                            "
                        >
                            <div class="sel-title">
                                <span
                                    class="title-icon"
                                    v-if="
                                        isVerification.gpsCalcName.isMandatory
                                    "
                                    >*</span
                                ><span class="title-text">{{
                                    isVerification.gpsCalcName.title
                                }}</span>
                            </div>
                            <div
                                class="sel-input"
                                :class="
                                    isVerification.gpsCalcName.text
                                        ? ''
                                        : 'dycolor'
                                "
                            >
                                {{
                                    isVerification.gpsCalcName.text
                                        ? isVerification.gpsCalcName.text
                                        : '请选择' +
                                          isVerification.gpsCalcName.title
                                }}
                            </div>
                        </div>
                    </el-popover>
                    <!-- 第三行 -->
                    <el-row>
                        <el-form-item label="是否强制检测海康信号源" prop="isForceCheckHk">
                            <el-select v-model="addForm.isForceCheckHk" placeholder="请选择">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" prop="remark">
                            <el-input
                                v-model="addForm.remark"
                                autocomplete="off"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="addSure()"
                        >确 认</el-button
                    >
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
<script src="https://unpkg.com/axios/dist/axios.min.js"></script>
<script>
import _ from 'lodash';
import store from '@/store/index.js'

export default {
    name: 'vehicleboardassociation',
    components: {},
    data() {
        return {
            options: [{
                value:true ,
                label: '是'
                }, {
                value: false,
                label: '否'
            }],
            tabData: [], // 表格数据源
            form: {
                carNum: '',
                simPhone:'',
                padName:''
            }, // 搜索表单
            total: 0, // 总数据条数
            paging: {
                pageSize: 10, // 每页显示数据条数
                PageNo: 1, // 当前页数
            }, // 分页表单
            orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
            addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
            selectForm: {}, // 选中的表单对象
            addForm: {
                isForceCheckHk:false
            }, // 添加表单
            // is: true,
            addFormRules: {
                carNum: [
                    {
                        required: true,
                        message: '请选择车牌号码',
                        trigger: 'blur',
                    },
                ],
                simPhone: [
                    {
                        required: true,
                        message: '请选择SIM卡',
                        trigger: 'blur',
                    },
                ],
            }, // 添加表单校验规则
            selectFormRules: {
                carNum: [
                    {
                        required: true,
                        message: '请选择车牌号码',
                        trigger: 'blur',
                    },
                ],
                simPhone: [
                    {
                        required: true,
                        message: '请选择SIM卡',
                        trigger: 'blur',
                    },
                ],
            },
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            fileList: [],
            isDisabled: true, // 控制对话框的编辑  (0.新加，1.提交注册，2.已注册，3.提交注销，4.已注销)
            statusList: [
                {
                    label: '新增',
                    status: '0',
                },
                {
                    label: '提交注册',
                    status: '1',
                },
                {
                    label: '已注册',
                    status: '2',
                },
                {
                    label: '提交注销',
                    status: '3',
                },
                {
                    label: '已注销',
                    status: '4',
                },
            ],
            deviceReceiverList: [],
            carNumList: [],
            terminalNameList: [],
            simNameList: [],
            simPhoneList: [],
            diskNameList: [],
            padNameList: [],
            s1: 1,
            n1: 1,
            carTotal: 0,
            isVerification: {
                carNum: {
                    title: '车牌号码',
                    text: '',
                    isMandatory: true,
                    is: false,
                    input: {
                        carNum: '',
                    },
                    data: null,
                    tableData: [],
                },
                simPhone: {
                    title: '车载SIM卡',
                    text: '',
                    isMandatory: true,
                    is: false,
                    input: {
                        sim: '',
                    },
                    data: null,
                    tableData: [],
                },
                terminalName: {
                    title: '车载终端名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        terminalName: '',
                    },
                    data: null,
                    tableData: [],
                },
                diskName: {
                    title: '车载硬盘名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        diskName: '',
                    },
                    data: null,
                    tableData: [],
                },
                padName: {
                    title: '车载平板名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        padName: '',
                        padId:'',
                        padSn:'',
                    },
                    data: null,
                    tableData: [],
                },
                terminalSnName: {
                    title: '设备编码',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        terminalSnName: '',
                    },
                    data: null,
                    tableData: [],
                },
                gpsName: {
                    title: '车载GPS名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        gpsName: '',
                        deviceSn:'',
                        uuid:'',
                    },
                    data: null,
                    tableData: [],
                },
                gpsCalcName: {
                    title: '里程计算方式',
                    text: '',
                    isMandatory: true,
                    is: false,
                    input: {
                        gpsCalcName: '',
                    },
                    data: null,
                    tableData: [],
                },
            },
            isReviseVerification: {
                carNum: {
                    title: '车牌号码',
                    text: '',
                    isMandatory: true,
                    is: false,
                    input: {
                        carNum: '',
                        deviceSn:'',
                        uuid:'',
                    },
                    data: null,
                    tableData: [],
                },
                simPhone: {
                    title: '车载SIM卡',
                    text: '',
                    isMandatory: true,
                    is: false,
                    input: {
                        sim: '',
                    },
                    data: null,
                    tableData: [],
                },
                terminalName: {
                    title: '车载终端名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        terminalName: '',
                    },
                    data: null,
                    tableData: [],
                },
                diskName: {
                    title: '车载硬盘名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        diskName: '',
                    },
                    data: null,
                    tableData: [],
                },
                padName: {
                    title: '车载平板名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        padName: '',
                        padId:'',
                        padSn:'',
                    },
                    data: null,
                    tableData: [],
                },
                terminalSnName: {
                    title: '设备编码',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        terminalSnName: '',
                    },
                    data: null,
                    tableData: [],
                },
                gpsName: {
                    // title: '车载GPS名称',
                    title: 'GPS设备名称',
                    text: '',
                    isMandatory: false,
                    is: false,
                    input: {
                        gpsName: '',
                    },
                    data: null,
                    tableData: [],
                },
                gpsCalcName: {
                    title: '里程计算方式',
                    text: '',
                    isMandatory: true,
                    is: false,
                    input: {
                        gpsCalcName: '',
                    },
                    data: null,
                    tableData: [],
                },
            },
            institutionsOptions: [] // 机构列表
        };
    },
     created() {
        this.getAllCar();
        this.getAllCarNum();
        this.getAllTerminalName();
        this.getAllsimName();
        this.getAllpad();
        this.getAlldisk();
        this.getInstitutions()
    },
    methods: {
          // 获取机构信息
    async getInstitutions () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope', {
        companyName: '',
        headers: {
          uuid: store.state.order_company || sessionStorage.getItem('order_company'),
          AUTH_TYPE: 'company'
        }
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取机构列表失败')
      this.institutionsOptions = res.data
    },
        async addBtn(){
            this.addDialogFormVisible = true;
            await this.searchgpsCalcNum(this.isVerification.gpsCalcName)
            if(this.isVerification.gpsCalcName.tableData||this.isVerification.gpsCalcName.tableData.length!=0){
                this.isVerification.gpsCalcName.text = this.isVerification.gpsCalcName.tableData[0].key;
                this.isVerification.gpsCalcName.input.gpsCalcName = this.isVerification.gpsCalcName.tableData[0].value;
            }

        },
        /**
         * gps计算  详情搜索
         */
        async searchgpsCalcDetailNum(data) {
            const { data: res } = await this.$http.get(
                '/pmService/tInfoVehicleDevice/getGpsCalcType?type=' +
                    'GPS_TYPE'
            );
            this.isReviseVerification.gpsCalcName.tableData = res;
        },
        selgpsCalcDetailName(data) {
            this.isReviseVerification.gpsCalcName.text = data.key;
            this.isReviseVerification.gpsCalcName.input.gpsCalcName = data.value;
            console.log(data.value,'data.value');

            this.isReviseVerification.gpsCalcName.is = false;
        },
        /**
         * gps计算 搜索
         */
        async searchgpsCalcNum(data) {
            const { data: res } = await this.$http.get(
                '/pmService/tInfoVehicleDevice/getGpsCalcType?type=' +
                    'GPS_TYPE'
            );
            this.isVerification.gpsCalcName.tableData = res;

        },
        selgpsCalcName(data) {
            this.isVerification.gpsCalcName.text = data.key;
            this.isVerification.gpsCalcName.input.gpsCalcName = data.value;
            this.isVerification.gpsCalcName.is = false;
        },
        /**
         * gps 搜索
         */
        async searchGpsNum(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.gpsName,
                    deviceType: '5',
                }
            );
            this.isVerification.gpsName.tableData = res.data.listByParams;
        },
        async clrGpsName() {
            this.isVerification.gpsName.input.uuid = '';
            this.isVerification.gpsName.input.gpsName = '';
            this.isVerification.gpsName.text = '';
            this.isVerification.gpsName.input.deviceSn=''
            this.searchGpsNum(this.isVerification.gpsName);
        },
        selGpsName(data) {
            console.log(data,'data');
            const {deviceSn,uuid,deviceName}=data
            this.isVerification.gpsName.text = deviceName;
            this.isVerification.gpsName.input.uuid = uuid;
            this.isVerification.gpsName.input.deviceSn = deviceSn;
            this.isVerification.gpsName.is = false;
        },
        /**
         * gps 详情
         */
        async searchReviseGpsName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.gpsName,
                    deviceType: '5',
                    // "status": 1
                }
            );
            this.isReviseVerification.gpsName.tableData = res.data.listByParams;
        },
        selReviseGpsName(data) {
             const {deviceSn,uuid,deviceName}=data
            this.isReviseVerification.gpsName.text = deviceName;
            this.isReviseVerification.gpsName.input.uuid = uuid;
            this.isReviseVerification.gpsName.input.deviceSn = deviceSn;
            this.isReviseVerification.gpsName.is = false;
        },
        clrReviseGpsName() {
            // this.isReviseVerification.gpsName.input.gpsId = '';
            this.isReviseVerification.gpsName.input.uuid = ''
            this.isReviseVerification.gpsName.input.gpsName = '';
            this.isReviseVerification.gpsName.input.deviceSn = '';
            this.isReviseVerification.gpsName.text = '';
            console.log(this.isReviseVerification.gpsName.input.uuid)

            this.searchReviseGpsName(this.isReviseVerification.gpsName);
        },
        // 车牌号码搜索方法
        async searchCarNum(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    carNum: data.input.carNum,
                    // "status": 1
                }
            );
            this.isVerification.carNum.tableData = res.data.carNumList;
        },
        selCarNum(data) {
            this.isVerification.carNum.text = data.carNum;
            this.isVerification.carNum.is = false;
        },
        clrCarNum() {
            this.isVerification.carNum.input.carNum = '';
            this.isVerification.carNum.text = '';
            this.searchCarNum(this.isVerification.carNum);
        },
        // sim搜索方法
        async searchSimPhone(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.simPhone,
                    deviceType: '3',
                    // "status": 1
                }
            );
            this.isVerification.simPhone.tableData = res.data.listByParams;
        },
        selSimPhone(data) {
            this.isVerification.simPhone.text = data.deviceSn;
            this.isVerification.simPhone.is = false;
        },
        clrSimPhone() {
            this.isVerification.simPhone.input.simPhone = '';
            this.isVerification.simPhone.text = '';
            this.searchSimPhone(this.isVerification.simPhone);
        },
        // 终端搜索方法
        async searchTerminalName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.terminalName,
                    deviceType: '0',
                    // "status": 1
                }
            );
            this.isVerification.terminalName.tableData = res.data.listByParams;
        },
        selTerminalName(data) {
            this.isVerification.terminalName.text = data.deviceSn;
            this.isVerification.terminalName.is = false;
        },
        clrTerminalName() {
            this.isVerification.terminalName.input.terminalName = '';
            this.isVerification.terminalName.text = '';
            this.searchTerminalName(this.isVerification.terminalName);
        },
        // 硬盘搜索方法
        async searchDiskName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.diskName,
                    deviceType: '4',
                    // "status": 1
                }
            );
            this.isVerification.diskName.tableData = res.data.listByParams;
        },
        selDiskName(data) {
            this.isVerification.diskName.text = data.deviceSn;
            this.isVerification.diskName.is = false;
        },
        clrDiskName() {
            this.isVerification.diskName.input.diskName = '';
            this.isVerification.diskName.text = '';
            this.searchDiskName(this.isVerification.diskName);
        },
        // 平板搜索方法
        async searchPadName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.padName,
                    deviceType: '2',
                    // "status": 1
                }
            );
            this.isVerification.padName.tableData = res.data.listByParams;
        },
        // 设备编码搜索
        async searchSnName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.terminalSnName,
                    deviceType: '0',
                    // "status": 1
                }
            );
            if (this.isReviseVerification.terminalSnName.is) {
                this.isReviseVerification.terminalSnName.tableData =
                    res.data.listByParams;
            } else {
                this.isVerification.terminalSnName.tableData =
                    res.data.listByParams;
            }
        },
        selPadName(data) {
            console.log(data,'data');
            this.isVerification.padName.text = data.deviceSn;
            this.isVerification.padName.input.padName = data.deviceName;
            this.isVerification.padName.input.padSn = data.deviceSn;
            this.isVerification.padName.input.padId = data.uuid;

            this.isVerification.padName.is = false;
        },
        selSnName(data) {
            if (this.isReviseVerification.terminalSnName.is) {
                this.isReviseVerification.terminalSnName.text = data.deviceSn;
                this.isReviseVerification.terminalSnName.is = false;
            } else {
                this.isVerification.terminalSnName.text = data.deviceSn;
                this.isVerification.terminalSnName.is = false;
            }
        },
        clrPadName() {
            this.isVerification.padName.input.padName = '';
            this.isVerification.padName.text = '';
            this.searchPadName(this.isVerification.padName);
        },
        clrSnName() {
            if (this.isReviseVerification.terminalSnName.is) {
                this.isReviseVerification.terminalSnName.input.terminalSnName =
                    '';
                this.isReviseVerification.terminalSnName.text = '';
                this.searchPadName(this.isReviseVerification.terminalSnName);
            } else {
                this.isVerification.terminalSnName.input.terminalSnName = '';
                this.isVerification.terminalSnName.text = '';
                this.searchPadName(this.isVerification.terminalSnName);
            }
        },
        // 给表格头部设定背景色
        rowClass({ row, rowIndex }) {
            return 'background:#e4eaec';
        },
        // 获取所有车载设备信息管理
        async getAllCar() {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoVehicleDevice/getTInfoVehicleDeviceList',
                {
                    pageSize: this.paging.pageSize,
                    pageNo: this.paging.PageNo,
                      carNum: this.form.carNum,
                        simPhone: this.form.simPhone,
                        padName: this.form.padName,
                        companyId: this.form.companyId,
                    // "status": 1
                }
            );
            if (res.statusCode !== '200')
                return this.$message.error('获取车载设备信息失败');
            this.tabData = res.data;
            this.total = +res.pageBean.pageDataCount;
        },
        // 获取所有车牌
        async getAllCarNum(v) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoVehicle/getTInfoVehicleList',
                {
                    carNum: v,
                }
            );
            // console.log(res)
            if (res.statusCode !== '200')
                return this.$message.error('获取车牌失败');
            this.carNumList = res.data;
            this.isVerification.carNum.data = res.data;
            // this.carTotal = +res.pageBean.pageDataCount
        },
        async remoteCarNumMethod(v) {
            if (v === '' || v === null) return;
            this.getAllCarNum(v);
        },
        // 获取所有车载终端  设备类型(0：车载终端、1：摄像头、2：平板、3：SIM卡、4：硬盘)
        async getAllTerminalName() {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    // "status": 1
                    deviceType: 0,
                }
            );
            // console.log(res);
            if (res.statusCode !== '200')
                return this.$message.error('获取车载设备信息失败');
            this.terminalNameList = res.data.records;
        },
        // 获取所有sim  设备类型(0：车载终端、1：摄像头、2：平板、3：SIM卡、4：硬盘)  tInfoDevice/getTInfoDeviceList
        async getAllsimName() {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    status: 0,
                    deviceType: 3,
                }
            );
            if (res.statusCode !== '200')
                return this.$message.error('获取sim失败');
            this.simPhoneList = res.data.records;
        },
        // 获取所有sim  设备类型(0：车载终端、1：摄像头、2：平板、3：SIM卡、4：硬盘)  tInfoDevice/getTInfoDeviceList
        async getAllpad() {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    status: 0,
                    deviceType: 2,
                }
            );
            // console.log(res)
            // console.log(1);
            if (res.statusCode !== '200')
                return this.$message.error('获取平板失败');
            this.padNameList = res.data.records;
        },
        // 获取所有硬盘  设备类型(0：车载终端、1：摄像头、2：平板、3：SIM卡、4：硬盘)
        async getAlldisk() {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    status: 0,
                    deviceType: 4,
                }
            );
            console.log(res);
            if (res.statusCode !== '200')
                return this.$message.error('获取硬盘失败');
            this.diskNameList = res.data.records;
        },
            /**
         * 重置
         */
        reset () {
            this.paging.PageNo = 1
            for (const key in this.form) {
                this.form[key] = ''
            }
            this.getAllCar()
        },
        // 点击搜索按钮触发
        async search() {
            // 判断有输入账号
            if (this.form.carNum === ''&&this.form.simPhone&&this.form.padName) {
                this.getAllCar();
            } else {
                // 调接口，进行查询
                this.paging.PageNo=1
                const { data: res } = await this.$http.post(
                    '/pmService/tInfoVehicleDevice/getTInfoVehicleDeviceList',
                    {
                        pageSize: this.paging.pageSize,
                        pageNo: this.paging.PageNo,
                        carNum: this.form.carNum,
                        simPhone: this.form.simPhone,
                        padName: this.form.padName,
                        companyId: this.form.companyId,
                    }
                );
                // 判断请求是否成功
                if (res.statusCode !== '200')
                    return this.$message.error('查询失败');
                this.tabData = res.data;
                this.total = +res.pageBean.pageDataCount;
            }
        },
        // 切换每页显示数量时触发
        handleSizeChange(newSize) {
            this.paging.pageSize = newSize;
            this.getAllCar();
        },
        // 切换页码时触发
        handlePageNumChange(PageNum) {
            this.paging.PageNo = PageNum;
            this.getAllCar();
        },
        // 点击查看详情按钮触发
        look(v) {
            this.isDisabled = true;
            this.selectForm = v;
            this.orderDialogFormVisible = true;
        },
        // 点击编辑按钮触发
        edit(v,isDisabled) {
            console.log(v);
            this.isDisabled = isDisabled;
            this.selectForm = _.cloneDeep(v);
            this.isReviseVerification.carNum.text = this.selectForm.carNum;
            this.isReviseVerification.simPhone.text = this.selectForm.simPhone;
            this.isReviseVerification.terminalName.text =
            this.selectForm.terminalName;
            this.isReviseVerification.terminalSnName.text=this.selectForm.terminalSn;
            this.isReviseVerification.diskName.text = this.selectForm.diskName;
            // 平板
            this.isReviseVerification.padName.text = this.selectForm.padName;
            this.isReviseVerification.padName.input.padName = this.selectForm.padName;
            this.isReviseVerification.padName.input.padSn = this.selectForm.padSn;
            this.isReviseVerification.padName.input.padId = this.selectForm.padId;
            // gps
            this.isReviseVerification.gpsName.text = this.selectForm.gpsName;
            this.isReviseVerification.gpsName.input.uuid=this.selectForm.gpsId
            this.isReviseVerification.gpsName.input.deviceSn=this.selectForm.gpsSn
            this.isReviseVerification.gpsCalcName.text = this.selectForm.gpsCalcTypeName;
            this.isReviseVerification.gpsCalcName.input.gpsCalcName = this.selectForm.gpsCalcType;
            this.orderDialogFormVisible = true;
            console.log(this.selectForm,this.isReviseVerification.gpsName);
        },
        // 点击删除按钮触发
        open(e) {
            this.$confirm('是否确认删除', '删除车辆与车载设备关联', {
                confirmButtonText: '确定删除',
                cancelButtonText: '取消删除',
                type: 'warning',
            })
                .then(async () => {
                    try {
                        const { data: res } = await this.$http.post(
                            '/pmService/tInfoVehicleDevice/deleteTInfoVehicleDevice',
                            JSON.parse(JSON.stringify(e))
                        );
                        // 判断是否删除成功
                        if (res.statusCode !== '200')
                            return this.$message.error(
                                '删除车辆与车载设备关联失败'
                            );
                        // 成功提示
                        this.$message.success('删除车辆与车载设备关联成功');
                        // 刷新视图
                        this.getAllCar();
                    } catch (e) {
                        console.log(e);
                    }
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        // 点击详情确定框触发
        async sure() {
            // 判断是出于查看详情还是编辑
            if (this.isDisabled) {
                return (this.orderDialogFormVisible = false);
            } else {
                if (!this.isReviseVerification.carNum.text)
                    return this.$message.error('车牌号码不能为空！');
                if (!this.isReviseVerification.simPhone.text)
                    return this.$message.error('手机号码不能为空！');
                //2就是立即定位
                if(this.isReviseVerification.gpsCalcName.input.gpsCalcName==2){
                    if (!this.isReviseVerification.gpsName.input.deviceSn) {
                         return this.$message.error('请选择GPS设备名称');
                    }
                }
                console.log(this.isReviseVerification.gpsName,'this.isReviseVerification.gpsName');
                this.selectForm.carNum = this.isReviseVerification.carNum.text;
                this.selectForm.simPhone =
                    this.isReviseVerification.simPhone.text;
                this.selectForm.terminalName =
                    this.isReviseVerification.terminalName.text;
                this.selectForm.diskName =
                    this.isReviseVerification.diskName.text;
                // 平板
                this.selectForm.padName =
                    this.isReviseVerification.padName.text;
                this.selectForm.padName = this.isReviseVerification.padName.input.padName;
                this.selectForm.padId = this.isReviseVerification.padName.input.padId;
                this.selectForm.padSn = this.isReviseVerification.padName.input.padSn;
                this.selectForm.gpsName =
                    this.isReviseVerification.gpsName.text;
console.log(this.isReviseVerification.gpsName.input.uuid)

                this.selectForm.gpsId =
                    this.isReviseVerification.gpsName.input.uuid;
                this.selectForm.gpsSn =
                    this.isReviseVerification.gpsName.input.deviceSn;
                this.selectForm.gpsCalcType =
                    this.isReviseVerification.gpsCalcName.input.gpsCalcName;
            this.selectForm.terminalSn = this.isReviseVerification.terminalSnName.text;

                // 进行编辑操作
                const { data: res } = await this.$http.post(
                    '/pmService/tInfoVehicleDevice/updateTInfoVehicleDevice',
                    JSON.parse(JSON.stringify(this.selectForm))
                );
                // 判断请求是否成功
                if (res.statusCode !== '200')
                    return this.$message.error('修改机构信息失败');
                // 成功提示
                this.$message.success('修改成功');
                // 重新渲染数据
                this.getAllCar();
                this.orderDialogFormVisible = false;
                // console.log(JSON.parse(JSON.stringify(this.selectForm)))
            }
        },
        // 添加机构--确定按钮
        async addSure() {
            console.log(this.isVerification.gpsCalcName);
            if (!this.isVerification.carNum.text)
                return this.$message.error('车牌号码不能为空！');
            if (!this.isVerification.simPhone.text)
                return this.$message.error('手机号码不能为空！');
             if (!this.isVerification.gpsCalcName.text)
                return this.$message.error('GPS计算方式不能为空！');
            // this.addForm.transportTypeId = +this.addForm.transportTypeId
            // this.addForm.companyId = +this.addForm.companyId
            this.addForm.status = '0';
            this.addForm.carNum = this.isVerification.carNum.text;
            this.addForm.simPhone = this.isVerification.simPhone.text;
            this.addForm.terminalName = this.isVerification.terminalName.text;
            this.addForm.diskName = this.isVerification.diskName.text;
            // 平板
            // this.addForm.padName = this.isVerification.padName.text;
            this.addForm.padName = this.isVerification.padName.input.padName;
            this.addForm.padId = this.isVerification.padName.input.padId;
            this.addForm.padSn = this.isVerification.padName.input.padSn;
            // gps
            this.addForm.gpsName = this.isVerification.gpsName.text;
            this.addForm.gpsId =this.isVerification.gpsName.input.uuid
            this.addForm.gpsSn =this.isVerification.gpsName.input.deviceSn
            this.addForm.terminalSn = this.isVerification.terminalSnName.text;
            this.addForm.gpsCalcType = this.isVerification.gpsCalcName.input.gpsCalcName ;
            console.log(this.isVerification.padName.text,'this.isVerification.padName.text');
            // 调接口，发送添加客户请求
            const { data: res } = await this.$http.post(
                '/pmService/tInfoVehicleDevice/addTInfoVehicleDevice',
                JSON.parse(JSON.stringify(this.addForm))
            );
            // 判断请求是否成功
            console.log(res);
            if (res.statusCode !== '200')
                return this.$message.error('添加转运车失败');
            // 成功提示
            this.$message.success('添加转运车成功');
            // 重新渲染数据
            this.getAllCar();
            // 关闭弹框
            this.addDialogFormVisible = false;
            // // 预校验
            // this.$refs.addForm.validate(async valid => {
            //   if (!valid) return
            //   this.addForm.transportTypeId = +this.addForm.transportTypeId
            //   this.addForm.companyId = +this.addForm.companyId
            //   // console.log(JSON.parse(JSON.stringify(this.addForm)));
            //   this.addForm.status = '0'
            //   // 调接口，发送添加客户请求
            //   const { data: res } = await this.$http.post('/pmService/tInfoVehicleDevice/addTInfoVehicleDevice', JSON.parse(JSON.stringify(this.addForm)))
            //   // 判断请求是否成功
            //   console.log(res);
            //   if (res.statusCode !== '200') return this.$message.error('添加转运车失败')
            //   // 成功提示
            //   this.$message.success('添加转运车成功')
            //   // 重新渲染数据
            //   this.getAllCar()
            //   // 关闭弹框
            //   this.addDialogFormVisible = false
            // })
        },
        // 监听添加用户对话框的关闭事件
        addDialogClose() {
            this.$refs.addForm.resetFields();
        },
        // 点击注册按钮触发
        async register(e) {
            console.log(e);
            const { data: res } = await this.$http.post(
                '/pmService/tInfoVehicleDevice/addHKDevice',
                JSON.parse(JSON.stringify(e))
            );
            // 判断请求是否成功
            console.log(res);
            // // 成功提示
            this.$message.success('注册成功');
            // 重新渲染数据
            this.getAllCar();
            window.location.reload();
        },
        // 点击订阅按钮触发 海康
        subscribe(e) {
            //       const { data: res } = await this.$http.post('http://192.168.123.40:8909/hkvsDeviceService/tInfoVehicleDevice/subscribeByDevices', JSON.parse(JSON.stringify(e.simPhone)))
            //       判断请求是否成功
            // if (res.statusCode !== '200') return this.$message.error('订阅失败')
            //       成功提示
            // console.log(res);
            //       this.$message.success('订阅成功')
            //       重新渲染数据
            //       this.getAllCar()
            //       window.location.reload()
        },
        // 点击注销按钮触发
        async logout(e) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoVehicleDevice/removeHKDevice',
                JSON.parse(JSON.stringify(e))
            );
            // 判断请求是否成功
            // console.log(res);
            if (res.statusCode !== '200')
                return this.$message.error('注销失败');
            // // 成功提示
            this.$message.success('注销成功');
            // 重新渲染数据
            this.getAllCar();
            window.location.reload();
        },
        // 车牌号码搜索方法
        async searchReviseCarNum(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    carNum: data.input.carNum,
                    // "status": 1
                }
            );
            this.isReviseVerification.carNum.tableData = res.data.carNumList;
        },
        selReviseCarNum(data) {
            this.isReviseVerification.carNum.text = data.carNum;
            this.isReviseVerification.carNum.is = false;
        },
        clrReviseCarNum() {
            this.isReviseVerification.carNum.input.carNum = '';
            this.isReviseVerification.carNum.text = '';
            this.searchReviseCarNum(this.isReviseVerification.carNum);
        },
        // sim搜索方法
        async searchReviseSimPhone(data) {
            if (this.isDisabled) {
                const value=this.isReviseVerification.simPhone.text
                this.$router.push({
                    name:'vehicleequipment',
                    params:{
                        deviceSn:value
                    }
                })
                return false
            }
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.simPhone,
                    deviceType: '3',
                    // "status": 1
                }
            );
            this.isReviseVerification.simPhone.tableData =
                res.data.listByParams;
        },
        selReviseSimPhone(data) {
            this.isReviseVerification.simPhone.text = data.deviceSn;
            this.isReviseVerification.simPhone.is = false;
        },
        clrReviseSimPhone() {
            this.isReviseVerification.simPhone.input.simPhone = '';
            this.isReviseVerification.simPhone.text = '';
            this.searchReviseSimPhone(this.isReviseVerification.simPhone);
        },
        // 终端搜索方法
        async searchReviseTerminalName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.terminalName,
                    deviceType: '0',
                    // "status": 1
                }
            );
            this.isReviseVerification.terminalName.tableData =
                res.data.listByParams;
        },
        selReviseTerminalName(data) {
            this.isReviseVerification.terminalName.text = data.deviceSn;
            this.isReviseVerification.terminalName.is = false;
        },
        clrReviseTerminalName() {
            this.isReviseVerification.terminalName.input.terminalName = '';
            this.isReviseVerification.terminalName.text = '';
            this.searchReviseTerminalName(
                this.isReviseVerification.terminalName
            );
        },
        // 硬盘搜索方法
        async searchReviseDiskName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.diskName,
                    deviceType: '4',
                    // "status": 1
                }
            );
            this.isReviseVerification.diskName.tableData =
                res.data.listByParams;
        },
        selReviseDiskName(data) {
            this.isReviseVerification.diskName.text = data.deviceSn;
            this.isReviseVerification.diskName.is = false;
        },
        clrReviseDiskName() {
            this.isReviseVerification.diskName.input.diskName = '';
            this.isReviseVerification.diskName.text = '';
            this.searchReviseDiskName(this.isReviseVerification.diskName);
        },
        // 平板搜索方法
        async searchRevisePadName(data) {
            const { data: res } = await this.$http.post(
                '/pmService/tInfoDevice/getTInfoDeviceList',
                {
                    deviceName: data.input.padName,
                    deviceType: '2',
                    // "status": 1
                }
            );
            this.isReviseVerification.padName.tableData = res.data.listByParams;
        },
        selRevisePadName(data) {
            console.log(data,'data');
            this.isReviseVerification.padName.text = data.deviceSn;
            this.isReviseVerification.padName.input.padName = data.deviceName;
            this.isReviseVerification.padName.input.padSn = data.deviceSn;
            this.isReviseVerification.padName.input.padId = data.uuid;
            this.isReviseVerification.padName.is = false;
        },
        clrRevisePadName() {
            this.isReviseVerification.padName.input.padName = '';
            this.isReviseVerification.padName.text = '';
            this.searchPadName(this.isReviseVerification.padName);
        },
    },
};
</script>
<style lang="less" scoped>
.ProportionPrepaid {
    width: 100%;
    height: 100%;

    //隐藏滚动条
    ::-webkit-scrollbar {
        // display: none;
        /* Chrome Safari */
    }

    .father-card {
        .btns {
            margin-bottom: 10px;
        }

        .tab {

            /deep/ .el-table {
                margin-bottom: 20px;
                .el-table__row--striped td {
                    background-color: #e4eaec !important;
                }

                .current-row > td {
                    background-color: #66b1ff !important;
                }
            }

        }

        .add-dialog,
        .order-dialog {
            .dialog-Title {
                color: #0097fe;
                font-size: 20px;
                font-weight: 700;
            }

            /deep/ .el-form {
                .subtitle {
                    color: #0097fe;
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    padding-left: 6px;
                    border-left: 5px solid #0097fe;
                }

                .el-row {
                    display: flex;

                    .el-form-item {
                        flex: 1;
                        display: flex;

                        .el-form-item__content {
                            flex: 1;

                            .el-input {
                                width: 100%;

                                .el-input__inner {
                                    width: 100%;
                                }
                            }

                            .el-select {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dy-sel {
    display: inline-block;
    align-items: center;
    margin: 0 0.125rem 0.275rem 0;

    > div {
        display: inline-block;
    }

    .sel-title {
        font-size: 0.175rem;
        margin-right: 0.15rem;

        .title-icon {
            color: #f56c6c;
            margin-right: 0.05rem;
        }
    }

    .sel-input {
        width: 2.87rem;
        height: 0.5rem;
        border-radius: 0.05rem;
        border: 0.0125rem solid #dcdfe6;
        box-sizing: border-box;
        padding: 0 0.1875rem;
        line-height: 0.5rem;
    }
}
/deep/ .el-pagination {
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    text-align: right;
 }
.dy-content {
    width: 100%;

    .content-head {
        height: 0.5rem;
        // background-color: pink;
        display: flex;
        align-items: center;

        .head-input {
            display: flex;
            align-items: center;
            margin-right: 0.1rem;

            span {
                white-space: nowrap;
                margin-right: 0.05rem;
            }

            .el-input {
                width: 1.5rem;
            }
        }
    }

    .content-table {
        margin-top: 0.2rem;
    }
}

.dycolor {
    color: #dcdfe6 !important;
}
</style>
